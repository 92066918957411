import {
  getArticleContent,
  getArticleContentLegacy,
  getArticleVotes,
} from '@backmarket/http-api/src/api-specs-content/endpoints'
import { type ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { useFetchFormattedPageContent } from '~/scopes/cms/composables/useFetchFormattedPageContent'

import { usePageParamsFromRoute } from '../../../cms/composables/usePageParamsFromRoute'

function useFetchVotes() {
  const { pageName } = usePageParamsFromRoute()

  return useHttpFetch(getArticleVotes, {
    pathParams: { articleSlug: pageName },
  })
}

const formatUpdateDate = (response: ArticlePageContent): ArticlePageContent => {
  const { updateDate, ...res } = response

  return {
    ...res,
    updateDate: updateDate ? new Date(updateDate) : undefined,
  }
}

// TODO COM-1345 Remove the param and use only the content service endpoint
function useFetchContent(countriesWithContentServiceEndpoint: string) {
  const pathParams = usePageParamsFromRoute()
  const { market } = useMarketplace()

  const endpoint = countriesWithContentServiceEndpoint.includes(
    market.countryCode,
  )
    ? getArticleContent
    : getArticleContentLegacy

  return useFetchFormattedPageContent(endpoint, {
    pathParams,
    transform: formatUpdateDate,
  })
}

export async function useFetchArticle(
  countriesWithContentServiceEndpoint = '',
) {
  const [fetchedContent, fetchedVotes] = await Promise.all([
    useFetchContent(countriesWithContentServiceEndpoint),
    useFetchVotes(),
  ])

  return { fetchedContent, fetchedVotes }
}
